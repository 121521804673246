import React from "react";

import UpperMenu from "../components/UpperMenu.js"
import Profile from "../components/Profile.js"
import { Navigate } from "react-router-dom";

const ModifyProfile = (props) => {

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

    // if(!loggedIn){
    //     return <Navigate to="/login"/>;
    // }

    return (
      <div>
          <UpperMenu/>
          <div className="d-flex center-content">
              <Profile isEditable={true}/>
          </div>
      </div>
    )
};

export default ModifyProfile;