import React from "react";

import UpperMenu from "../components/UpperMenu";
import Profile from "../components/Profile";
import { Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const ProfilePage = (props) => {

    var { user } = useParams();

    var profileOwner = user;

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

    // if(!loggedIn){
    //     return <Navigate to="/login"/>;
    // }

    return (
        <div>
            <UpperMenu/>
            <div className="d-flex center-content">
                <Profile profileOwner={profileOwner}/>
            </div>
        </div>
    )
};

export default ProfilePage;