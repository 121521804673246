import React from "react";

import UpperMenu from "../components/UpperMenu.js"
import { Link } from "react-router-dom";
import Brasao from "../images/brasao_rg.png";
import { Navigate } from "react-router-dom";

const Content = (props) => {

    return (
        <div className="default-page-content-size">
            <div className="px-4">
                <div className="center-content">
                    <div className="simple-container flex-column">
                        <div className="d-flex center-content">
                            <div className="w-33 d-flex pb-4">
                                <img className="w-100" src={Brasao}/>
                            </div>
                        </div>
                        <div className="title2 d-flex center-content pb-2">
                            Câmara Municipal do Rio Grande
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb-4"></div>
            <div className="searches px-4">
                <div className="center-content flex pb-4">
                    <Link className="w-50" to="/proposition_search">
                        <div className="simple-container d-flex center-content">
                            Buscar proposição
                        </div>
                    </Link>
                    <div className="px-2"></div>
                    <Link className="w-50" to="/councilors">
                        <div className="simple-container d-flex center-content">
                            Parlamentares
                        </div>
                    </Link>
                </div>
                <div className="center-content flex">
                    <Link className="w-50" to="/orders">
                        <div className="simple-container d-flex center-content">
                            Ordens do dia
                        </div>
                    </Link>
                    <div className="px-2"></div>
                    <Link className="w-50" to="/contact">
                        <div className="simple-container d-flex center-content">
                            Contato
                        </div>
                    </Link>
                </div>
            </div>
            <div className="p-4">
                <div className="simple-container">
                    <div className="title2 pb-2">
                        Como chegar
                    </div>
                    <div className="relative">
                        <div className="w-100 ratio-1-1"></div>
                        <iframe className="w-100 h-100 absolute" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3382.2476429052003!2d-52.10249361017765!3d-32.03548772469616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95119c0e5700f06f%3A0x1670af289d30ab9d!2sR.%20Gen.%20Vitorino%2C%20441%20-%20Centro%2C%20Rio%20Grande%20-%20RS%2C%2096200-310!5e0!3m2!1spt-BR!2sbr!4v1703877492695!5m2!1spt-BR!2sbr" style={{border:0}} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Home = () => {

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

    // if(!loggedIn){
    //     return <Navigate to="/login"/>;
    // }

    return (
        <div>
            <UpperMenu/>
            <div className="default-container">
                <Content/>
            </div>
        </div>
    )
};

export default Home;