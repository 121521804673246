import React from "react";

import UpperMenu from "../components/UpperMenu.js"
import Contact from "../components/Contact.js";
import Form from "../components/Form.js";
import dbQuery from "../gets/DBQuery.js";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const Content = (props) => {

    var isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
    var user_id = isLoggedIn? JSON.parse(localStorage.getItem("user_id")) : null;
    const [isCouncilor, setIsCouncilor] = useState(false);

    var contacts = [
        <Contact name="Consultoria Jurídica" phone="(53) 32338538"/>,
        <Contact name="Recursos Humanos" phone="(53) 32338587"/>,
        <Contact name="Setor de Protocolo" phone="(53) 32338575"/>,
        <Contact name="Secretaria Geral" phone="(53) 32338597"/>,
        <Contact name="Setor de Compras" phone="(53) 32338586"/>,
        <Contact name="Setor de Manutenção" phone="(53) 32338564"/>,
        <Contact name="Almoxarifado e Patrimônio" phone="(53) 32338591"/>,
        <Contact name="Secretaria do Legislativo/ Comissões Técnicas" phone="(53) 32338524"/>,
        <Contact name="Ouvidoria" email="portalsic@camarariogrande.rs.gov.br" phone="(53) 32338520"/>,
        <Contact name="Centro de Processamento de Dados" phone="(53) 32338583"/>,
        <Contact name="Arquivo" phone="(53) 32338566"/>,
        <Contact name="TV Câmara" phone="(53) 32338522"/>,
        <Contact name="Setor de Contabilidade" phone="(53) 32338596"/>,
        <Contact name="Tesouraria" phone="(53) 32338504"/>,
        <Contact name="Sonorização" phone="(53) 32338521"/>,
        <Contact name="Setor de Cerimonial e Protocolo da Câmara Municipal" phone="(53) 32338510"/>
    ]

    var contacts_html = contacts.map((contact)=>{
        var last = contacts[contacts.length - 1];

        return (
            <div className={"pt-2 pb-2" + (contact !== last ? " default-border-bottom" : "")}>
                {contact}
            </div>
        )
    });

    var contact_inputs;

    if(isLoggedIn){
        contact_inputs = {
            1:{
                placeholder:"Mensagem", type:"textarea", name:"message", required:true
            },
            2:{
                btn:true, type:"submit", value:"Enviar", required:true
            },
        }
    }
    else{
        contact_inputs = {
            1:{
                placeholder:"Nome", type:"text", name:"name", required:true
            },
            2:{
                placeholder:"Email", type:"email", name:"email", required:true
            },
            3:{
                placeholder:"Mensagem", type:"textarea", name:"message", required:true
            },
            4:{
                btn:true, type:"submit", value:"Enviar", required:true
            },
        }
    }

    // sender_id bigint,
    // receiver_id bigint,
    // msg text not null,
    // time_sent varchar(100) not null,

    const handleMessageSubmit = (event) => {
        event.preventDefault();
        var { name, email, message } = document.getElementsByClassName("contact-form")[0];

        if(isLoggedIn){
            // fetch user info from db

            dbQuery("get_user_by_id", {
                id: user_id
            }, (user)=>{
                user = user[0];

                name = {value: user.username};
                email = {value: user.email};

                var data = {
                    msg: message.value,
                    time_sent: new Date().toISOString(),
                    username: name.value,
                    email: email.value
                }

                dbQuery("send_contact_message", data, (data)=>{
                    alert("Mensagem enviada com sucesso!");
                    window.location.reload();
                });
            });
        }
        else{
            var data = {
                msg: message.value,
                time_sent: new Date().toISOString(),
                username: name.value,
                email: email.value
            }

            dbQuery("send_contact_message", data, (data)=>{
                alert("Mensagem enviada com sucesso!");
                window.location.reload();
            });
        }
    }

    const [messages, setMessages] = useState([]);

    //check if user is a councilor
    useEffect(()=>{
        dbQuery("get_councilor_by_user_id", {
            user_id: user_id
        }, (data)=>{
            if(data.length > 0){
                setIsCouncilor(true);

                //get all msgs
                dbQuery("get_all_contact_messages", {}, (data)=>{
                    let new_messages = [];
                    for(var i = 0; i < data.length; i++){
                        var msg = data[i];
                        let is_last = i === data.length - 1;
                        let classes = "pt-2 pb-2" + (is_last ? "" : " default-border-bottom");
                        new_messages.push(
                            <div className={classes}>
                                <div className="pb-1">
                                    <div className="bold">{msg.username}</div>
                                    <div>{msg.email}</div>
                                </div>
                                <div>{msg.msg}</div>
                            </div>
                        )
                    }
                    setMessages(new_messages);
                });
            }
        });
    }, [user_id]);

    return (
        <div className="default-page-content-size">
            <div className="contacts px-4">
                <div className="contact center-content flex pb-4">
                    <div className="w-100" to="/proposition_search">
                        <div className="simple-container">
                            <div className="pt-2">
                                <div className="title pb-2">Câmara Municipal do Rio Grande</div>
                                <div className="pb-1">Endereço: Rua General Vitorino, n° 441, Centro, Rio Grande/RS</div>
                                <div className="pb-1">CEP: 96200-310</div>
                                <Contact phone="(53) 32338500" email="cmrg@camarariogrande.rs.gov.br"/>
                                <div className="pb-1">CNPJ: 89 584 981 0001 - 75</div>
                                <div className="pb-1">Horário de atendimento: de segunda-feira a sexta-feira, 13 horas até às 19 horas</div>
                            </div>
                        </div>
                        <div className="pb-4"></div>
                        <div className="simple-container">
                            <div className="title pb-2 d-flex center-content">
                                Contatos
                            </div>
                            {contacts_html}
                        </div>
                        <div className="pb-4"></div>
                        {!isCouncilor &&
                        <div className="simple-container">
                            <div className="title pb-2 d-flex center-content">
                                Fale com a Câmara
                            </div>
                            <div className="pb-2">Caso queira enviar uma mensagem para a Câmara Municipal do Rio Grande, preencha o formulário abaixo:</div>
                            <Form inputs={contact_inputs} formFunction={handleMessageSubmit} className={"contact-form"}/>
                        </div>}
                        {isCouncilor &&
                            <div className="simple-container">
                                <div className="title pb-2 d-flex center-content">
                                    Mensagens
                                </div>
                                {messages}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const Contacts = () => {
    const state = { ...localStorage };

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

    // if(!loggedIn){
    //     return <Navigate to="/login"/>;
    // }

    return (
        <div>
            <UpperMenu/>
            <div className="default-container">
                <Content/>
            </div>
        </div>
    )
};

export default Contacts;